module.exports = [
  {
    "key": "racism-covid",
    "title": "The case of two pandemics: COVID-19 and racism",
    "tags": [
      "Data visualization",
      "Illustrator"
    ],
    "thumbnail": require("../__assets__/images/grid-view-thumbnails/racismcovid.png"),
    "protected": false
  },
  {
    "key": "language-app",
    "title": "Learning Hangeul through Hunminjeongeum and the Atomic Design Principle",
    "tags": [
      "Language learning",
      "Sketch",
      "Hunminjeongeum UNESCO"
    ],
    "thumbnail": require("../__assets__/images/grid-view-thumbnails/languageapp.png"),
    "protected": false
  },
  {
    "key": "organize-layers",
    "title": "Order matters: top to bottom, bottom to top mental frameworks",
    "tags": [
      "frameworks",
      "Sketch",
      "Github"
    ],
    "thumbnail": require("../__assets__/images/grid-view-thumbnails/ordermatters.png"),
    "protected": false
  },
  {
    "key": "southwest-airlines",
    "title": "Matters of the heart: Designing an airline ecosystem",
    "tags": [
      "Microsoft Sharepoint",
      "Azure DevOps",
      "Sketch"
    ],
    "thumbnail": null,
    "protected": true
  }
  // ,
  // {
  //   "key": "exquisite-solutions",
  //   "title": "Exquisite solutions: the beautiful futures we create",
  //   "tags": [
  //     "Surrealism",
  //     "User generated content",
  //     "Public discourse"
  //   ],
  //   "thumbnail": require("./__assets__/images/grid-view-thumbnails/placeholder.png"),
  //   "protected": false
  // }
]
