import React from 'react';
import styled from 'styled-components';
import ListView from './ListView';
import GridView from './GridView';
import WORK_DATA from '../PROJECT_DATA.js';

/* -----------------------------------------------------------------
 * Styles for the WorkView page.
 * ----------------------------------------------------------------- */

const WorkViewContainer = styled.div`
  display: grid;
  justify-content: center;

  @media (max-width: 769px) {
    align-items: center;
    padding: 30px;
  }
`;

/* -----------------------------------------------------------------
 * This is the parent container for the work view page. It renders
 * either the ListView or GridView depending on which view was
 * chosen.
 * ----------------------------------------------------------------- */

const WorkView = ({ view, fromMenuView, toggleView }) => {
  return (
    <WorkViewContainer>
      {view === 'list' ? (
        <ListView
          data={WORK_DATA}
          fromMenuView={fromMenuView}
          toggleView={toggleView}
        />
      ) : (
        <GridView
          data={WORK_DATA}
          fromMenuView={fromMenuView}
          toggleView={toggleView}
        />
      )}
    </WorkViewContainer>
  );
};

export default WorkView;
