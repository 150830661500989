import React from 'react';
import styled from 'styled-components';
import GridViewItem from './GridViewItem';

/* -----------------------------------------------------------------
 * Styles for the GridView.
 * ----------------------------------------------------------------- */

const GridViewContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 436.25px) minmax(auto, 436.25px);
  grid-auto-rows: 436.25px;
  border-top: 0.25px solid rgba(0, 0, 0, 0.2);
  border-left: 0.25px solid rgba(0, 0, 0, 0.2);

  @media (max-width: 1024px) {
    grid-template-columns: minmax(auto, 436.25px);
  }
`;

/* -----------------------------------------------------------------
 * This is the component that will render the pieces of
 * the GridView.
 * ----------------------------------------------------------------- */

const GridView = ({ data, fromMenuView, toggleView }) => {
  return (
    <GridViewContainer>
      {data.map((item, index) => (
        <GridViewItem
          key={index}
          index={index}
          title={item.title}
          tags={item.tags}
          thumbnail={item.thumbnail}
          isProtected={item.protected}
          fromMenuView={fromMenuView}
          toggleView={toggleView}
        />
      ))}
    </GridViewContainer>
  );
};

export default GridView;
