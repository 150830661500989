import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PasswordInputField from './PasswordInputField.jsx';
import CloseIcon from '@assets/images/close.svg';

const PasswordModalContainer = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const PasswordModalOuter = styled.div`
  height: 388px;
  width: 436px;
  padding: 35px;
  background: #fff;
  box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const PasswordModalInner = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
`;

const PasswordModalCloseIcon = styled.img`
  height: 15px;
  color: #000;
  margin-top: 5px;
  align-self: flex-end;
`;

const Title = styled.div`
  text-decoration: underline;
  font-family: 'Noto Serif KR', serif;
  font-weight: 600;
  font-size: 24px;
  text-align: left;
  color: #000;
  letter-spacing: 0;
  margin-bottom: 85px;
`;

const PasswordModal = ({ title }) => {
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();

  return (
    <PasswordModalContainer
      style={{ visibility: showModal ? 'visible' : 'hidden' }}
    >
      <PasswordModalOuter>
        <PasswordModalCloseIcon src={CloseIcon} onClick={history.goBack} />
        <PasswordModalInner>
          <Title>{title}</Title>
          <PasswordInputField
            gridView
            hidden={false}
            onSubmit={() => setShowModal(false)}
          />
        </PasswordModalInner>
      </PasswordModalOuter>
    </PasswordModalContainer>
  );
};

export default PasswordModal;
