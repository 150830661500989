import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import styled from 'styled-components';

import ScrollToTop from '@components/ScrollToTop';
import NavigationMenu from '@components/NavigationMenu';
import ViewChanger from '@components/ViewChanger';
import Header from './Header';
import Footer from './Footer';

import WorkView from '@views/Work';
import MiscellaneousView from '@views/Miscellaneous';
import AboutView from '@views/About';
import SouthwestAirlines from '@views/Work/projects/SouthwestAirlines';
import LanguageApp from '@views/Work/projects/LanguageApp';
import OrganizeLayers from '@views/Work/projects/OrganizeLayers';
import RacismCovid from '@views/Work/projects/RacismCovid';
import WIP from '@views/Work/projects/WIP';

import WORK_DATA from '../PROJECT_DATA.js';

/* -----------------------------------------------------------------
 * Styles for the ApplicationLayout.
 * ----------------------------------------------------------------- */

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto 50px;

  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

/* -----------------------------------------------------------------
 * Dictionary list to determine which component to render based on
 * which project was chosen.
 * ----------------------------------------------------------------- */

const COMPONENT_LIST = {
  'southwest-airlines': SouthwestAirlines,
  'language-app': LanguageApp,
  'organize-layers': OrganizeLayers,
  'racism-covid': RacismCovid,
  'exquisite-solutions': WIP,
};

const Project = () => {
  const { slug } = useParams();

  // Generates a URL slug based on the title of the project
  const slugify = (text) =>
    text
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');

  const project = WORK_DATA.find((data) => slugify(data.title) === slug);
  const index = WORK_DATA.findIndex((data) => slugify(data.title) === slug);
  const prevIndex = index === 0 ? WORK_DATA.length - 1 : index - 1;
  const nextIndex = index === WORK_DATA.length - 1 ? 0 : index + 1;

  return React.createElement(COMPONENT_LIST[project.key], {
    prev: `/work/${slugify(WORK_DATA[prevIndex].title)}`,
    next: `/work/${slugify(WORK_DATA[nextIndex].title)}`,
    title: project.title,
    protectedProject: project.protected,
    isPrevProtected: WORK_DATA[prevIndex].protected,
    isNextProtected: WORK_DATA[nextIndex].protected,
  });
};

/* -----------------------------------------------------------------
 * The ApplicationLayout module. This is the root module that will
 * render the rest of the views on the site.
 * ----------------------------------------------------------------- */

const ApplicationLayout = () => {
  // Determines which view to render: list or grid (default: list)
  const [workView, setWorkView] = useState('list');
  const [showMenuList, setShowMenuList] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setWorkView('list');
      setShowMenuList(false);
    }

    return () => (mounted = false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleMenuList = (viewClicked) => {
    if (!viewClicked) {
      setShowMenuList(false);
    }

    if (!showMenuList) {
      setShowMenuList(true);
    }

    if (showMenuList && workView === viewClicked) {
      setShowMenuList(false);
    }

    if (workView === 'grid' && viewClicked === 'list') {
      setWorkView('list');
    } else if (workView === 'list' && viewClicked === 'grid') {
      setWorkView('grid');
    }
  };

  const renderMenuList = () => {
    return (
      <WorkView view={workView} fromMenuView toggleView={toggleMenuList} />
    );
  };

  const renderMainContent = () => {
    return (
      <Switch>
        <Route
          exact
          path={['/', '/work']}
          render={() => <WorkView view={workView} />}
        />
        <Route path="/miscellaneous" component={MiscellaneousView} />
        <Route path="/about" component={AboutView} />
        <Route path={`/work/:slug`} component={Project} />
      </Switch>
    );
  };

  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Layout>
          <ScrollToTop />
          <Header />
          <MainContainer>
            <NavigationMenu />
            {showMenuList ? renderMenuList() : renderMainContent()}
            <ViewChanger
              view={workView}
              onChangeView={setWorkView}
              showMenuList={showMenuList}
              toggleMenuList={toggleMenuList}
            />
          </MainContainer>
          <Footer />
        </Layout>
      </LastLocationProvider>
    </BrowserRouter>
  );
};

export default ApplicationLayout;
