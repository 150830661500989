import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/* -----------------------------------------------------------------
 * This module ensures we always land at the top of the page on
 * page redirect.
 * ----------------------------------------------------------------- */

const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0));
    return () => unlisten();
  }, [history]);

  return null;
};

export default ScrollToTop;
