import React from 'react';
import ReactDOM from 'react-dom';
import ApplicationLayout from '@views/Application/ApplicationLayout';

/* -----------------------------------------------------------------
 * Below are the global imports for font files.
 * ----------------------------------------------------------------- */
import '@assets/fonts/NHaasGroteskTXPro-55Rg.ttf';
import '@assets/fonts/NHaasGroteskTXPro-65Md.ttf';
import '@assets/fonts/NHaasGroteskTXPro-75Bd.ttf';
import '@assets/fonts/NotoSerifKR-Light.otf';
import '@assets/fonts/NotoSerifKR-Regular.otf';
import '@assets/fonts/NotoSerifKR-Medium.otf';
import '@assets/fonts/NotoSerifKR-SemiBold.otf';
import '@assets/fonts/NotoSerifKR-Bold.otf';
import '@assets/fonts/NotoSerifKR-Black.otf';
import '@assets/fonts/nimbus-sans-l_regular.ttf';

/* -----------------------------------------------------------------
 * Below are the global imports for CSS stylesheets.
 * ----------------------------------------------------------------- */
import 'pure-react-carousel/dist/react-carousel.es.css';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ApplicationLayout />
  </React.StrictMode>,
  document.getElementById('root')
);
