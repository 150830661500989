import React from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

const ImageStyled = styled.img`
  margin: 56px 0;
  height: auto;
  width: 100%;
`;

const Image = (props) => {
  return (
    <LazyLoad offsetTop={200}>
      <ImageStyled {...props} />
    </LazyLoad>
  );
};

export default Image;
