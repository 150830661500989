import React, { useState } from 'react';
import styled from 'styled-components';
import ListViewItem from './ListViewItem';

/* -----------------------------------------------------------------
 * Styles for the ListView.
 * ----------------------------------------------------------------- */

const ListViewContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    padding-left: 65px;
  }

  @media (min-width: 1023px) {
    padding-left: 165px;
  }
`;

const ListViewItemContainer = styled.div`
  ${(props) => (props.isSelected ? '' : 'opacity: 0.3;')}
`;

/* -----------------------------------------------------------------
 * This is the component that will render the pieces of
 * the ListView.
 * ----------------------------------------------------------------- */

const ListView = ({ data, fromMenuView, toggleView }) => {
  const [isSelected, setIsSelected] = useState(null);

  return (
    <ListViewContainer>
      {data.map((item, index) => (
        <ListViewItemContainer
          key={index}
          isSelected={isSelected === index || isSelected === null}
          onMouseLeave={() => setIsSelected(null)}
        >
          <ListViewItem
            key={index}
            index={index}
            title={item.title}
            tags={item.tags}
            isProtected={item.protected}
            onSelected={setIsSelected}
            fromMenuView={fromMenuView}
            toggleView={toggleView}
          />
        </ListViewItemContainer>
      ))}
    </ListViewContainer>
  );
};

export default ListView;
