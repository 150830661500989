import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LockIcon from '@assets/images/lock.svg';
import PasswordInputField from '@components/PasswordInputField.jsx';
import Title from './subcomponents/Title';
import Tags from './subcomponents/Tags';
import { displayNumber, slugify } from './helpers';

const ListViewItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: all 0.3s;

  ${(props) =>
    props.shouldRenderTags ? ':hover { margin-bottom: 25px; }' : ''}

  @media (max-width: 769px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListViewNumber = styled.span`
  margin-left: -10px;
  margin-right: 10px;
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 32px;

  @media (max-width: 769px) {
    margin-left: 0;
  }
`;

const Icon = styled.img`
  color: #000;
  margin-left: 10px;
  vertical-align: text-top;
`;

const ListViewItem = ({
  index,
  title,
  tags,
  isProtected,
  onSelected,
  fromMenuView,
  toggleView,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  let history = useHistory();

  const shouldRenderTags = tags.length > 0 && isHovering && !showPasswordField;

  const handleSelect = () => {
    setIsHovering(true);
    onSelected(index);
  };

  const handleDeselect = () => {
    setIsHovering(false);
  };

  const goToProject = () => {
    onSelected(index);

    if (isProtected && !fromMenuView) {
      setShowPasswordField(!showPasswordField);
      return;
    }

    toggleView && toggleView();
    history.push(`/work/${slugify(title)}`);
  };

  const onSubmit = () => {
    setShowPasswordField(false);
    toggleView && toggleView();
    history.push(`/work/${slugify(title)}`);
  };

  return (
    <ListViewItemContainer
      shouldRenderTags={shouldRenderTags}
      onMouseEnter={handleSelect}
      onMouseLeave={handleDeselect}
    >
      <ListViewNumber>{displayNumber(index)}</ListViewNumber>
      <TitleContainer>
        <Title isHovering={isHovering} onClick={goToProject}>
          {title}
          {isProtected && <Icon src={LockIcon} />}
        </Title>
        <Tags shouldRenderTags={shouldRenderTags} tags={tags} />
        {isProtected && (
          <PasswordInputField hidden={!showPasswordField} onSubmit={onSubmit} />
        )}
      </TitleContainer>
    </ListViewItemContainer>
  );
};

export default ListViewItem;
