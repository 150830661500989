import React from 'react';
import ProjectBase from './ProjectBase';
// import Text from '../subcomponents/Text';
// import TextTwoColumns from '../subcomponents/TextTwoColumns';
// import Image from '../subcomponents/Image';
// import Carousel from '../subcomponents/Carousel';

const WIP = (props) => {
  return (
    <ProjectBase {...props}>
      <header>
        Work in Progress
      </header>
    </ProjectBase>
  );
};

export default WIP;
