import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LockIcon from '@assets/images/lock.svg';
import PasswordInputField from '@components/PasswordInputField';
import Title from './subcomponents/Title';
import Tags from './subcomponents/Tags';
import { displayNumber, slugify } from './helpers';

/* -----------------------------------------------------------------
 * Styles for the GridViewItem.
 * ----------------------------------------------------------------- */

const GridViewItemContainer = styled.div`
  display: grid;
  grid-template-columns: 20px auto 20px;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.2);
  border-right: 0.25px solid rgba(0, 0, 0, 0.2);
  padding: 30px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const GridViewNumber = styled.span`
  margin-right: 10px;
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 32px;
`;

const Icon = styled.img`
  color: #000;
  margin-top: 12px;
  margin-left: 10px;
  align-self: flex-start;
`;

const ProjectThumbnail = styled.img`
  margin: 30px 0;
  height: 190px;
  width: auto;
`;

/* -----------------------------------------------------------------
 * This component renders each cell in the GridView.
 * ----------------------------------------------------------------- */

const GridViewItem = ({
  index,
  title,
  tags,
  thumbnail,
  isProtected,
  fromMenuView,
  toggleView,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  let history = useHistory();

  const shouldRenderTags = tags.length > 0 && isHovering;

  const handleSelect = () => {
    setIsHovering(true);
  };

  const handleDeselect = () => {
    setIsHovering(false);
  };

  const goToProject = () => {
    if (isProtected && !fromMenuView) {
      setShowPasswordField(!showPasswordField);
      return;
    }

    toggleView && toggleView();
    history.push(`/work/${slugify(title)}`);
  };

  const onSubmit = () => {
    setShowPasswordField(false);
    toggleView && toggleView();
    history.push(`/work/${slugify(title)}`);
  };

  return (
    <GridViewItemContainer
      onMouseEnter={handleSelect}
      onMouseLeave={handleDeselect}
    >
      <GridViewNumber>{displayNumber(index)}</GridViewNumber>
      <TitleContainer>
        <Title
          isHovering={isHovering}
          onClick={goToProject}
          style={{ minHeight: '115px' }}
        >
          {title}
        </Title>
        {isProtected || <ProjectThumbnail src={thumbnail} />}
        {isProtected && (
          <PasswordInputField
            gridView
            hidden={!showPasswordField}
            onSubmit={onSubmit}
          />
        )}
        <Tags shouldRenderTags={shouldRenderTags} tags={tags} />
      </TitleContainer>
      {isProtected && <Icon src={LockIcon} />}
    </GridViewItemContainer>
  );
};

export default GridViewItem;
