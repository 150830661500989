import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

/* -----------------------------------------------------------------
 * Styled components for the NavigationMenu.
 * ----------------------------------------------------------------- */

const NavigationMenuContainer = styled.nav`
  min-width: 135px;
  padding-left: 30px;

  @media (max-width: 769px) {
    padding-left: 0;
    margin-bottom: 50px;
    text-align: center;
  }
`;

const activeClassName = 'nav-link-active';
const NavigationMenuItem = styled(NavLink).attrs({ activeClassName })`
  color: #000;
  display: block;
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 27px;
  text-decoration: none;

  &.${activeClassName}, &:hover {
    font-weight: 400;
  }
`;

/* -----------------------------------------------------------------
 * This module renders the navigation menu on the left
 * ----------------------------------------------------------------- */

const NavigationMenu = () => {
  const isActiveSelection = (_match, location) => {
    return location.pathname === '/' || location.pathname.includes('/work');
  };

  return (
    <NavigationMenuContainer>
      <NavigationMenuItem exact to="/" isActive={isActiveSelection}>
        work
      </NavigationMenuItem>
      <NavigationMenuItem to="/miscellaneous">miscellaneous</NavigationMenuItem>
      <NavigationMenuItem to="/about">about</NavigationMenuItem>
    </NavigationMenuContainer>
  );
};

export default NavigationMenu;
