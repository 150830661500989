import React from 'react';
import ProjectBase from './ProjectBase';
import Text from '../subcomponents/Text';
import TextTwoColumns from '../subcomponents/TextTwoColumns';
import Image from '../subcomponents/Image';
import Carousel from '../subcomponents/Carousel';

import LanguageApp1 from '@assets/images/languageapp/languageapp_1.png';
import LanguageApp2 from '@assets/images/languageapp/languageapp_2.png';
import LanguageApp3 from '@assets/images/languageapp/languageapp_3.png';
import LanguageApp4 from '@assets/images/languageapp/languageapp_4.png';
import LanguageApp5 from '@assets/images/languageapp/languageapp_5.png';
import LanguageApp6 from '@assets/images/languageapp/languageapp_6.png';
import LanguageApp7 from '@assets/images/languageapp/languageapp_7.png';
import LanguageApp8 from '@assets/images/languageapp/languageapp_8.png';
import LanguageApp9 from '@assets/images/languageapp/languageapp_9.png';
import LanguageApp10 from '@assets/images/languageapp/languageapp_10.png';
import LanguageApp11 from '@assets/images/languageapp/languageapp_11.png';
import LanguageApp15 from '@assets/images/languageapp/languageapp_15.png';
import LanguageApp16 from '@assets/images/languageapp/languageapp_16.png';
import LanguageApp17 from '@assets/images/languageapp/languageapp_17.png';
import LanguageApp18 from '@assets/images/languageapp/languageapp_18.png';
import LanguageApp19 from '@assets/images/languageapp/languageapp_19.png';
import LanguageApp20 from '@assets/images/languageapp/languageapp_20.png';
import LanguageApp21 from '@assets/images/languageapp/languageapp_21.png';
import LanguageApp22 from '@assets/images/languageapp/languageapp_22.png';
import LanguageApp23 from '@assets/images/languageapp/languageapp_23.png';
import LanguageAppCarousel1 from '@assets/images/languageapp/languageapp_carousel_1.png';
import LanguageAppCarousel2 from '@assets/images/languageapp/languageapp_carousel_2.png';
import LanguageAppCarousel3 from '@assets/images/languageapp/languageapp_carousel_3.png';
import LanguageAppCarousel4 from '@assets/images/languageapp/languageapp_carousel_4.png';
import LanguageAppCarousel5 from '@assets/images/languageapp/languageapp_carousel_5.png';
import LanguageAppCarousel6 from '@assets/images/languageapp/languageapp_carousel_6.png';
import LanguageAppCarousel7 from '@assets/images/languageapp/languageapp_carousel_7.png';
import LanguageAppCarousel8 from '@assets/images/languageapp/languageapp_carousel_8.png';

const LanguageApp = (props) => {
  return (
    <ProjectBase {...props}>
      <header>
        Learning Hangeul through Hunminjeongeum
        <br />
        and the Atomic Design Principle
      </header>
      <Image src={LanguageApp1} />
      <TextTwoColumns>
        <Text textAlign="left">
          By breaking down the Hangeul writing system into atoms, we introduce
          the basics of the Korean language.
        </Text>
        <Text variant={2} textAlign="left">
          Most Korean learners are acquainted with Korean through entertainment
          (like K-POP, dramas, or their social circles), and are drawn by its
          easy pronunciation and simplistic, phonetic writing system. This is in
          comparison to more complex writing systems, like the Chinese writing
          system.
        </Text>
      </TextTwoColumns>
      <Image src={LanguageApp2} />
      <TextTwoColumns>
        <Text textAlign="left">
          What is atomic design and how does it help in learning Hangeul?
        </Text>
        <Text variant={2} textAlign="left">
          Brad Frost created a design system methodology called Atomic Design
          that consists of five descriptors: Atoms, Molecules, Organisms,
          Templates, Pages. I mirrored this principle of using building blocks
          to create a vast system with combinations of simple components to
          learning the characters of Hangeul.
        </Text>
      </TextTwoColumns>
      <Image src={LanguageApp3} />
      <Image src={LanguageApp4} />
      <TextTwoColumns>
        <Text textAlign="left">
          Consonants and Vowels as Atoms to introduce Korean philosophy and
          foundational characters
        </Text>
        <Text variant={2} textAlign="left">
          Hangeul consists of 14 consonants and 10 vowels, called Jaeum and
          Moeum respectively. Jaeum and Moeum are all phonetically pronounced
          and once a handful of foundational “atoms” are recognized, the rest of
          the consonants and vowels are easier to memorize.
          <br />
          <br />
          The design principle of the basic characters are considered
          hieroglyphic, or “to imitate shapes.” Jaeum are modeled after the
          features of the articulatory organs when they are pronounced.
        </Text>
      </TextTwoColumns>
      <Image src={LanguageApp5} />
      <Image src={LanguageApp6} />
      <TextTwoColumns>
        <Image src={LanguageApp7} style={{ margin: 0 }} />
        <Text variant={2} textAlign="left" style={{ marginTop: '55px' }}>
          Consonants are not only grouped by visual similarity, but by how they
          are pronounced with the articulatory organs.
        </Text>
      </TextTwoColumns>
      <Image src={LanguageApp8} />
      <Text
        size="small"
        variant={2}
        textAlign="left"
        style={{ marginBottom: '60px' }}
      >
        These three abstract lines are the atoms used to develop the vowel
        writing structure. Founded in the Confucian principle of Three powers
        and vowel harmony described under yin and yang principles, these three
        abstract symbols were designed to represent perfect balance.
      </Text>
      <TextTwoColumns>
        <Image src={LanguageApp9} style={{ margin: 0 }} />
        <Text variant={2} textAlign="left" style={{ marginTop: '88px' }}>
          The dot would be modified to be another extended line in modernized
          versions of Hangeul that is being used today. Unlike consonants, these
          vowels are only known by their sounds and do not have specific
          character names like its counterparts.
          <br />
          <br />
          —<br />
          <br />
          By memorizing both sets of atoms, actual characters that make the
          Korean alphabet are then created by the combination of the consonants
          and vowels. As characters are put together, a series of characters
          then create organisms.
        </Text>
      </TextTwoColumns>
      <TextTwoColumns>
        <Text textAlign="left">
          Syllable blocks as Organisms to Write All Forms of Hangeul
        </Text>
        <Text variant={2} textAlign="left">
          Similar to atomic design, as characters are put together, a series of
          characters, or atoms, then create organisms. In this case, by
          memorizing the Jaeum and Moeum, they create syllable blocks, the
          required combinations to write any Hangeul word.
          <br />
          <br />
          —<br />
          <br />
          Hangeul is written from left to right, top to bottom and uses a
          phonological system. One syllable block must contain at least one
          vowel and a consonant in order to write a correct, Hangeul character.
        </Text>
      </TextTwoColumns>
      <Image src={LanguageApp10} style={{ width: '507px' }} />
      <Text
        textAlign="left"
        style={{
          alignSelf: 'flex-start',
          marginLeft: '20px',
          padding: '0',
          width: '100',
        }}
      >
        Syllable block components
      </Text>
      <div style={{ width: '100%', marginLeft: '40px', textAlign: 'left' }}>
        <Image
          src={LanguageApp11}
          style={{ marginTop: '20px', width: '392px' }}
        />
      </div>
      <div style={{ width: '551px' }}>
        <Carousel centerImages>
          <img src={LanguageAppCarousel1} />
          <img src={LanguageAppCarousel2} />
          <img src={LanguageAppCarousel3} />
          <img src={LanguageAppCarousel4} />
          <img src={LanguageAppCarousel5} />
          <img src={LanguageAppCarousel6} />
          <img src={LanguageAppCarousel7} />
          <img src={LanguageAppCarousel8} />
        </Carousel>
      </div>
      <TextTwoColumns>
        <Text textAlign="left">
          Demonstrating Hangeul in its full capacity showing its forms as
          Organism.
        </Text>
        <Text variant={2} textAlign="left">
          With the compilation of atoms, molecules and organisms, when syllable
          blocks are put together, they create the means of communication in the
          Korean written form.
        </Text>
      </TextTwoColumns>
      <Image src={LanguageApp15} />
      <Text variant={2}>
        This pangram shows all simple consonants and vowels, a few special
        characters and a diphthong. By pressing on a specific syllable block,
        users can see which of these words fit in said syllable block.
      </Text>
      <Image src={LanguageApp16} />
      <Text variant={2}>
        Another example that demonstrates when choosing a specific syllable
        block, users can identify which of these characters fit into that
        specific syllable block category.
      </Text>
      <TextTwoColumns>
        <Text textAlign="left">
          Using the Hangeul writing system to define learning needs and
          requirements
        </Text>
        <Text variant={2} textAlign="left">
          For application purposes, designing an app. that teaches and quizzes
          Hangeul was a means to cement any new learnings, as well as allow
          users to practice and review what they’ve already learned.
        </Text>
      </TextTwoColumns>
      <Image src={LanguageApp17} />
      <TextTwoColumns>
        <Text textAlign="left">
          Testing findings and incorporating structure to application
        </Text>
        <Text variant={2} textAlign="left">
          The final outcome is a prototype that incorporates all the linguistic
          foundations, design thinking approach and cultural homages to further
          learning about Hangeul and the alphabet to equip learners a
          comprehensive and progressional way to learn Hangeul.
        </Text>
      </TextTwoColumns>
      <Image
        src={LanguageApp18}
        style={{ width: '593px', marginBottom: '30px', marginTop: '80px' }}
      />
      <Text variant={2} style={{ width: '650px', marginTop: 0 }}>
        Home screen that allows users to select a Chapter to begin their
        learning.
      </Text>
      <Image
        src={LanguageApp19}
        style={{ marginBottom: '30px', marginTop: '80px' }}
      />
      <Text variant={2} style={{ width: '650px', marginTop: 0 }}>
        The first exercise within a lesson starts with instructing users how to
        pronounce the character, what the character looks like and the
        appropriate stroke order to write the character.
      </Text>
      <Image
        src={LanguageApp20}
        style={{ marginBottom: '30px', marginTop: '80px' }}
      />
      <Text variant={2} style={{ width: '688px', marginTop: 0 }}>
        The next exercise gives the most guidance when learning each character.
        The screen shows an outline of the shape and instructs users to draw in
        the correct stroke order to accurate complete the exercise.
      </Text>
      <Image
        src={LanguageApp21}
        style={{ marginBottom: '30px', marginTop: '80px' }}
      />
      <Text variant={2} style={{ width: '650px', marginTop: 0 }}>
        The proceeding exercise removes the outline, but gives a small hint for
        stroke order. Users will know which character to write because the name
        of the character is written below. They can also click on the
        pronunciation icon to know how exactly to pronounce said character.
      </Text>
      <Image
        src={LanguageApp22}
        style={{ marginBottom: '30px', marginTop: '80px' }}
      />
      <Text variant={2} style={{ width: '650px', marginTop: 0 }}>
        The last writing exercise takes all hints away and allows users to test
        their knowledge to demonstrate the correct writing of the character,
        incorporating stroke order.
      </Text>
      <Image
        src={LanguageApp23}
        style={{ marginBottom: '30px', marginTop: '80px' }}
      />
      <Text
        variant={2}
        style={{ width: '650px', marginTop: 0, marginBottom: '80px' }}
      >
        Lastly, users are prompted to accurately pronounce the character they’ve
        just written to complete a lesson. In this case, going through the
        exercises of writing and pronouncing the character Giyeok in the
        Consonants chapter.
      </Text>
      <Text size="medium" textAlign="left">
        An approachable method that takes away the burden of trying to learn a
        language from the onset of learning, but rather allowing users to simply
        recognize patterns and visuals in an interactive way that focuses on
        simple, cumulative progression.
      </Text>
      <Text size="small" variant={2} textAlign="left">
        The compilation of data and initial information architecture led to
        various methods of learning even within the early stages of the atomic
        design. By introducing even the simplest forms with diction, stroke
        order for the character, as well as the ability for users to verbally
        repeat the character’s name, these micro-interactions are to reiterate
        and affirm users’ of their knowledge. The repetitive lesson structure is
        used in even the most complex progressions of the Hangeul alphabet to
        ensure that users are focused on learning the new material, and not any
        other adjustment from the application. This approach reduces any
        unnecessary cognitive load to ensure optimal learning of one subject—the
        Korean alphabet.
      </Text>
    </ProjectBase>
  );
};

export default LanguageApp;
