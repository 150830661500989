import React from 'react';
import styled from 'styled-components';
import Miscellaneous1 from '@assets/images/miscellaneous/miscellaneous_1.png';
import Miscellaneous2 from '@assets/images/miscellaneous/miscellaneous_2.png';
import Miscellaneous3 from '@assets/images/miscellaneous/miscellaneous_3.png';
import Miscellaneous4 from '@assets/images/miscellaneous/miscellaneous_4.png';
import Miscellaneous5 from '@assets/images/miscellaneous/miscellaneous_5.png';
import Miscellaneous6 from '@assets/images/miscellaneous/miscellaneous_6.png';
import Miscellaneous7 from '@assets/images/miscellaneous/miscellaneous_7.png';
import Miscellaneous8 from '@assets/images/miscellaneous/miscellaneous_8.png';

/* -----------------------------------------------------------------
 * Styles for the Miscellaneous view.
 * ----------------------------------------------------------------- */

const MiscellaneousViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  grid-template-areas:
    '. header header header header header header header header header header .'
    '. content content content content content content content content content content .';
`;

const MiscellaneousViewHeader = styled.h2`
  max-width: 565px;
  grid-area: header;
  font-family: 'Noto Serif KR';
  font-size: 24px;
  font-weight: 300;
  line-height: 35px;
  text-align: center;
  margin: 0 auto 60px;
`;

const MiscellaneousViewGrid = styled.div`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const MiscellaneousViewImage = styled.img`
  width: 100%;
`;

/* -----------------------------------------------------------------
 * This is the component that renders the Miscellaneous view page.
 * ----------------------------------------------------------------- */

const MiscellaneousView = () => {
  return (
    <MiscellaneousViewContainer>
      <MiscellaneousViewHeader>
        A collection of other works, images and any sorts of visual streams of
        consciousness
      </MiscellaneousViewHeader>
      <MiscellaneousViewGrid>
        <MiscellaneousViewImage src={Miscellaneous1} />
        <MiscellaneousViewImage src={Miscellaneous2} />
        <a href="/makkuro_kurosukerun/index.html" target="_blank">
          <MiscellaneousViewImage src={Miscellaneous3} />
        </a>
        <MiscellaneousViewImage src={Miscellaneous4} />
        <MiscellaneousViewImage src={Miscellaneous5} />
        <MiscellaneousViewImage src={Miscellaneous6} />
        <MiscellaneousViewImage src={Miscellaneous7} />
        <MiscellaneousViewImage src={Miscellaneous8} />
      </MiscellaneousViewGrid>
    </MiscellaneousViewContainer>
  );
};

export default MiscellaneousView;
