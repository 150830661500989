import React from 'react';
import styled from 'styled-components';

const TagsContainer = styled.div`
  display: block;
  height: 15px;
`;

const TagItem = styled.span`
  color: #6a6a6a;
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;

  :not(:last-of-type):after {
    content: ' - ';
  }
`;
const Tags = ({ tags, shouldRenderTags }) => {
  return (
    <TagsContainer>
      {shouldRenderTags &&
        tags.map((tag, index) => <TagItem key={index}>{tag}</TagItem>)}
    </TagsContainer>
  );
};

export default Tags;
