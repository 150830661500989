import React from 'react';
import styled from 'styled-components';

const TextTwoColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 161px;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;

  div {
    padding: 0;
  }
`;

const TextTwoColumns = ({ children }) => {
  return <TextTwoColumnsContainer>{children}</TextTwoColumnsContainer>;
};

export default TextTwoColumns;
