import React from 'react';
import { useForm } from 'react-hook-form';
import bcrypt from 'bcryptjs';
import styled from 'styled-components';

const PasswordInputForm = styled.form`
  height: 60px;

  ${props => props.hasError ? 'margin-bottom: 30px;' : '' };
`;

const PasswordInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 3px solid ${props => props.hasError ? '#f22222' : '#000' };
  color: ${props => props.hasError ? '#f22222' : '#000' };
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 27px;
  font-weight: 400;
  line-height: 27px;

  &:focus{
    outline: none;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
  }
`;

const PasswordInputError = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #f22222;
`;

const ErrorText = styled.p`
  margin: 0;
`;

const PasswordInputField = ({ gridView, hidden, onSubmit }) => {
  const { register, handleSubmit, errors, setError, clearErrors } = useForm();

  const handleOnChange = (e) => {
    if (e.target.value === '') {
      clearErrors('password');
    }
  }

  const checkPassword = (data) => {
    // Use https://bcrypt-generator.com/ to encrypt your password and paste the hash here
    const HASH = '$2y$12$A95bh00JKRYupmsANGd8y.8JgtbEo/EW6Ka/E6cR1h1FoLQDKx1qK';
    const isMatch = bcrypt.compareSync(data.password, HASH);

    if (isMatch) {
      onSubmit();
    } else {
      setError('password', {
        type: 'manual',
        message: gridView ?
          'Password is incorrect.\nUse the authorized password for access.' :
          'Password is incorrect. Use the authorized password for access.'
      });
    }
  }

  const renderError = (text) => {
    return text.split('\n').map((item, i) => <ErrorText key={i}>{item}</ErrorText>)
  };

  if (hidden) {
    return null;
  }

  return (
    <PasswordInputForm onSubmit={handleSubmit(checkPassword)} hasError={errors.password}>
      <PasswordInput
        autoFocus
        type='password'
        name='password'
        placeholder='Password'
        ref={register({ required: true })}
        onChange={handleOnChange}
        hasError={errors.password}
      />
      {errors.password && (
        <PasswordInputError>
          {renderError(errors.password.message)}
        </PasswordInputError>
      )}
    </PasswordInputForm>
  );
};

export default PasswordInputField;
