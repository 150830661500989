import React from 'react';
import ProjectBase from './ProjectBase';
import Text from '../subcomponents/Text';
import TextTwoColumns from '../subcomponents/TextTwoColumns';
import Image from '../subcomponents/Image';
import Carousel from '../subcomponents/Carousel';

import Southwest1 from '@assets/images/southwest/southwest_1.png';
import Southwest2 from '@assets/images/southwest/southwest_2.png';
import Southwest3 from '@assets/images/southwest/southwest_3.png';
import Southwest4 from '@assets/images/southwest/southwest_4.png';
import Southwest5 from '@assets/images/southwest/southwest_5.png';
import Southwest6 from '@assets/images/southwest/southwest_6.png';
import Southwest7 from '@assets/images/southwest/southwest_7.png';
import Southwest8 from '@assets/images/southwest/swa_toolsappsview.png';
import SouthwestCarousel1 from '@assets/images/southwest/southwest_carousel_1.png';
import SouthwestCarousel2 from '@assets/images/southwest/southwest_carousel_2.png';
import SouthwestCarousel3 from '@assets/images/southwest/southwest_carousel_3.png';
import SouthwestCarousel4 from '@assets/images/southwest/southwest_carousel_4.png';
import SouthwestCarousel5 from '@assets/images/southwest/southwest_carousel_5.png';
import SouthwestCarousel6 from '@assets/images/southwest/southwest_carousel_6.png';
import SouthwestCarousel7 from '@assets/images/southwest/southwest_carousel_7.png';

const SouthwestAirlines = (props) => {
  return (
    <ProjectBase {...props}>
      <header>
        Matters of the Hearts:
        <br />
        Designing an airline ecosystem
      </header>
      <Text>
        Designing a best-in-class brand and digital experience that reflects
        Southwest Airlines’ ethos and commitment to its employees: to take care
        of its people so that they will take care of the business.
      </Text>
      <Image src={Southwest1} />
      <Text size="small">
        Southwest Airlines is an internationally iconic brand known for its
        strong core values and brand promise.
      </Text>
      <Text size="small" variant={2} textAlign="left">
        Southwest Airlines’ promise of dedication of highest quality of service
        and care not only is a promise to its customers, but to its employees.
        Their brand, its customer service, and its employees are best-in-class,
        but their technological infrastructure was not serving them. Our job was
        to reimagine their digital experience and to create an employee portal
        that worked like its fellow Co-Hearts: with incredible speed, smarts and
        with heart.
      </Text>
      <TextTwoColumns>
        <Text textAlign="left">
          Identifying “Who are the Users?” through Stakeholder Interviews and
          Categorization of Working Styles.
        </Text>
        <Text variant={2} textAlign="left">
          By establishing five, distinct persona categories, employees were able
          to express their unique needs, pain points and listed technologies and
          commnication preferences in order to complete their tasks and move
          onto their next agenda.
        </Text>
      </TextTwoColumns>
      <Carousel>
        <img src={SouthwestCarousel1} />
        <img src={SouthwestCarousel2} />
        <img src={SouthwestCarousel3} />
        <img src={SouthwestCarousel4} />
      </Carousel>
      <TextTwoColumns>
        <Text textAlign="left">
          Aligning Persona Needs and Areas of Opportunity through Business
          Requirements.
        </Text>
        <Text variant={2} textAlign="left">
          Prioritization led to identifying key features and technologies from
          “must have” to “can be without” under four contextual lenses of “Does
          it help me do my job?” “Does this inform me to do my job?” and “Ways
          to promote SWA” as shown below.
        </Text>
      </TextTwoColumns>
      <Image src={Southwest2} style={{ marginTop: '100px' }} />
      <Image src={Southwest3} />
      <Text size="small" variant={2} textAlign="left">
        By using a content-driven alternative to visual wireframes, this allowes
        stakeholders and key persona groups to openly list what is important to
        them to do their jobs and exude pride for their company, without being
        fixated on visuals. These priority guide excercises are also are used as
        mutual agreement amongst stakeholders to firmly align on business and
        technological requirements for the project.
      </Text>
      <Image src={Southwest4} style={{ marginTop: '60px' }} />
      <TextTwoColumns>
        <Text textAlign="left">
          A Robust Network Tailored to Employees’ Personal Needs.
        </Text>
        <Text variant={2} textAlign="left">
          Personalization was ranked based on a compilation of business and
          technological requirements, frequency of use and task-oriented tools
          and applications taking priority. The visual design emphasized these
          priorities with “must have” information on top and “nice to have”
          information cascading downward.
        </Text>
      </TextTwoColumns>
      <Image src={Southwest5} style={{ marginTop: '80px' }} />
      <Image src={Southwest6} style={{ marginTop: '30px' }} />
      <Image src={Southwest7} />
      <TextTwoColumns>
        <Text textAlign="left">A Powerful Search</Text>
        <Text variant={2} textAlign="left">
          The hallmark to a truly best-in-class intranet is its capability to
          search anything and everything. Through Microsoft Sharepoint and
          Teams, employees able to search accurately and send information with
          ease at the touch of a button.
        </Text>
      </TextTwoColumns>
      <Carousel>
        <img src={SouthwestCarousel5} />
        <img src={SouthwestCarousel6} />
        <img src={SouthwestCarousel7} />
      </Carousel>
      <TextTwoColumns>
        <Text textAlign="left">
          Empowerment through tools and applications on any platform at your
          fingertips.
        </Text>
        <Text variant={2} textAlign="left">
          Whether in the air, on the ground and in between every transit,
          employees will have an arsenal of tools and applications with
          unparalleled connectivity at their fingertips to complete every task
          to match their can-do spirit.
        </Text>
      </TextTwoColumns>
      <Image src={Southwest8} />
      <Text size="small">
        All About the Heart: empowered by technology, fueled by the Warrior
        Spirit.
      </Text>
      <Text size="small" variant={2} textAlign="left">
        The digital, transformation experience is the foundation of Southwest
        Airlines’ promise of dedication to its employees and is just one of ways
        Southwest Airlines continues to expand, refine and commit to the care
        for its Co-Hearts. By creating a streamlined digital experience, this
        gave employees a thriving environment with robust technologies and a
        network of seamless communication points with precision and ease to
        match their unparalleled can-do attitude and Warrior Spirit.
      </Text>
    </ProjectBase>
  );
};

export default SouthwestAirlines;
