import React from 'react';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';
import ProjectBase from './ProjectBase';
import Text from '../subcomponents/Text';

import HandAnimation from '@assets/videos/covid_hand_animation_v2.mp4';
import FlowersAnimation from '@assets/videos/covid_flowers_animation_v2.mp4';
import MaskAnimation from '@assets/videos/covid_mask_animation_v2.mp4';

const ReactPlayerContainer = styled.div`
  width: 100%;
  margin: 50px auto 0;
`;

const RacismCovid = (props) => {
  return (
    <ProjectBase {...props}>
      <header>
        The case of two pandemics:
        <br />
        COVID-19 and racism
      </header>
      <Text size="small" variant={2} textAlign="left">
      There is no question that Coronavirus (COVID-19) has changed our lives. From mask-wearing mandates,
      exercising social distancing, regimented curfews, the constant plea to wash hands, the urgency to
      fight COVID-19 has been persistent. COVID-19 has also drawn political lines and has shown the glaring
      discrepancies and inequalities of our healthcare and governmental systems. With the rise of COVID-19 cases
      and its current effects on society, there also have been hate-related crimes surging towards people of
      East Asian backgrounds and their associated communities.
        <br />
        <br />
        Since March of 2020 when the World Health Organization declared a global
        pandemic, there has been a concerted effort to “flatten the curve” of
        the virus, as well as proactive, vocal efforts to raise awareness of
        racism toward the Asian/Asian-American Pacific Islander (AAPI)
        community. From verbal attacks, discrimination across all levels of
        society and industries, to physical violence, to vocalizing xenophobic
        sentiments, these vignettes are a brief description of how Anti-Asian
        racism and COVID-19 are currently coinciding in the United States today.
        <br />
        <br />
        As much as I am a designer, I am first and foremost a Korean-American
        female living in the United States. This project is not only a
        continuing form of expression, but also a rally call to fight alongside
        those who are standing in solidarity to combat both the spread of
        COVID-19 and hate. This project will continue as I document not only
        what the media documents, but social accounts and personal anecdotes.
      </Text>
      <ReactPlayerContainer>
        <ReactPlayer
          loop
          muted
          playing
          url={HandAnimation}
          width="100%"
          height="100%"
        />
      </ReactPlayerContainer>
      <Text size="small" variant={2} textAlign="left">
        Our very humanity is dependent on the social connections we have with
        one another and that includes how we treat and mistreat each other.
        Unlike the people and institutions that continue to perpetruate hate,
        discrimination and inequality, COVID-19 does not discriminate but
        continues to spread and wreak havoc on all of our communities.
      </Text>
      <ReactPlayerContainer>
        <ReactPlayer
          loop
          muted
          playing
          url={MaskAnimation}
          width="100%"
          height="100%"
        />
      </ReactPlayerContainer>
      <Text size="small" variant={2} textAlign="left">
        There is a continuing controversy around mask and facial covering
        mandates in its efficacy, as well as its debate on violating freedoms.
        Masks are also one of the essential items of frontline workers and there
        is a dire shortage of personal protective equipment (PPE) amongst
        medical professionals and staff that are giving care and fighting the
        massive rise of COVID-19 infections.
      </Text>
      <ReactPlayerContainer>
        <ReactPlayer
          loop
          muted
          playing
          url={FlowersAnimation}
          width="100%"
          height="100%"
        />
      </ReactPlayerContainer>
      <Text size="small" variant={2} textAlign="left">
        Despite our current circumstances, COVID-19 has revealed the tenacity,
        kindness and resilience of humanity. As we continue to fight together in
        solidarity, we honor and thank those who have fought and continue to
        fight against COVID-19, hate and discrimination.
      </Text>
    </ProjectBase>
  );
};

export default RacismCovid;
