import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* -----------------------------------------------------------------
 * Styles for the Header.
 * ----------------------------------------------------------------- */

const HeaderContainer = styled.div`
  margin-top: 70px;
  margin-bottom: 80px;
  text-align: center;

  @media (max-width: 769px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const HeaderText = styled(Link)`
  color: #000;
  font-family: 'Noto Serif KR', serif;
  font-size: 16px;
  font-weight: 900;
  text-decoration: none;
`;

/* -----------------------------------------------------------------
 * This is the module that renders the site header.
 * ----------------------------------------------------------------- */

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderText to="/">eunice ju young son</HeaderText>
    </HeaderContainer>
  );
};

export default Header;
