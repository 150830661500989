import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ListViewIcon from '@assets/images/list-view.svg';
import GridViewIcon from '@assets/images/grid-view.svg';
import CloseIcon from '@assets/images/close.svg';

/* -----------------------------------------------------------------
 * Styled components for the ViewChanger.
 * ----------------------------------------------------------------- */

const ViewChangerContainer = styled.nav`
  min-width: 100px;
  padding-right: 30px;
  text-align: right;

  @media (max-width: 769px) {
    display: none;
  }
`;

const Icon = styled.img`
  height: 14px;
  width: 14px;
  padding: 5px 10px;
  color: #000;
  opacity: ${(props) => (props.active ? 1 : 0.4)};

  &:not(:last-of-type) {
    border-right: 1px solid #d8d8d8;
  }
`;

/* -----------------------------------------------------------------
 * This is the component that renders the toggle component that
 * changes the view to either list or grid view.
 * ----------------------------------------------------------------- */

const ViewChanger = ({ view, onChangeView, showMenuList, toggleMenuList }) => {
  const location = useLocation();

  if (location && location.pathname.match(/\/work\//g)) {
    return (
      <ViewChangerContainer>
        <Icon
          src={showMenuList && view === 'list' ? CloseIcon : ListViewIcon}
          active={view === 'list'}
          onClick={() => toggleMenuList('list')}
        />
        <Icon
          src={showMenuList && view === 'grid' ? CloseIcon : GridViewIcon}
          active={view === 'grid'}
          onClick={() => toggleMenuList('grid')}
        />
      </ViewChangerContainer>
    );
  }

  return (
    <ViewChangerContainer>
      <Icon
        src={ListViewIcon}
        active={view === 'list'}
        onClick={() => onChangeView('list')}
      />
      <Icon
        src={GridViewIcon}
        active={view === 'grid'}
        onClick={() => onChangeView('grid')}
      />
    </ViewChangerContainer>
  );
};

ViewChanger.defaultProps = {
  view: 'list',
};

export default ViewChanger;
