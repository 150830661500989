/* -----------------------------------------------------------------
 * This file contains various helper methods that are used in this
 * project.
 * ----------------------------------------------------------------- */

/** Generates a number display text with leading zeros */
export const displayNumber = (index) => {
  return index < 10 ? `0${index + 1}` : index + 1;
};

/** Generates a slug for URL display */
export const slugify = (text) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
