import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import styled from 'styled-components';
import LoadingBar from '@components/LoadingBar';
import PasswordModal from '@components/PasswordModal.jsx';
import LockIcon from '@assets/images/lock.svg';

const ProjectContainer = styled.div`
  max-width: 920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  header {
    font-family: 'Noto Serif KR', serif;
    font-size: 24px;
    letter-spacing: 0;

    font-weight: ${(props) => props.fontWeight || 700};
  }

  @media (max-width: 769px) {
    padding: 20px;
  }
`;

const ProjectNav = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 332px));
  margin-top: 120px;
`;

const ProjectNavHeader = styled.div`
  margin-bottom: 16px;
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 10px;
  font-weight: 600;
`;

const ProjectNavLink = styled(Link)`
  font-family: 'Noto Serif KR', serif;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  color: #000;
`;

const Icon = styled.img`
  height: 12px;
  color: #000;
  margin-top: 5px;
  margin-left: 10px;
  vertical-align: text-top;
`;

const ProjectBase = ({
  children,
  prev,
  next,
  title,
  protectedProject,
  isPrevProtected,
  isNextProtected,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const lastLocation = useLastLocation();

  const reverseSlug = (slug) => {
    return (
      slug &&
      slug
        .toLowerCase()
        .replace(/\/work\//g, '')
        .split('-')
        .map((str) => str[0].toUpperCase() + str.substr(1))
        .join(' ')
    );
  };

  const askForPassword =
    !lastLocation || (lastLocation && lastLocation.pathname.match(/\/work\//g));

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset;
    const winHeight = window.innerHeight;
    const docHeight = getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPos = Math.floor((scrollTop / totalDocScrollLength) * 100);
    setScrollPosition(scrollPos);
  };

  const onScroll = () => {
    requestAnimationFrame(() => {
      calculateScrollDistance();
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    // removing event listener on component unmount
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProjectContainer>
      <LoadingBar scroll={`${scrollPosition}%`} />
      {protectedProject && askForPassword && <PasswordModal title={title} />}
      {children}
      <ProjectNav>
        <div style={{ textAlign: 'left' }}>
          <ProjectNavHeader>Prev</ProjectNavHeader>
          <ProjectNavLink to={prev}>
            {reverseSlug(prev)}
            {isPrevProtected && <Icon src={LockIcon} />}
          </ProjectNavLink>
        </div>
        <div>
          <ProjectNavHeader>Menu</ProjectNavHeader>
          <ProjectNavLink to="/">Show all projects</ProjectNavLink>
        </div>
        <div style={{ textAlign: 'right' }}>
          <ProjectNavHeader>Next</ProjectNavHeader>
          <ProjectNavLink to={next}>
            {reverseSlug(next)}
            {isNextProtected && <Icon src={LockIcon} />}
          </ProjectNavLink>
        </div>
      </ProjectNav>
    </ProjectContainer>
  );
};

export default ProjectBase;
