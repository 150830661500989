import React from 'react';
import ProjectBase from './ProjectBase';
import Text from '../subcomponents/Text';
import TextTwoColumns from '../subcomponents/TextTwoColumns';
import Image from '../subcomponents/Image';
import Carousel, { CarouselCaption } from '../subcomponents/Carousel';

import OrganizeLayers1 from '@assets/images/organizelayers/organizelayers_1.gif';
import OrganizeLayers2 from '@assets/images/organizelayers/organizelayers_2.png';
import OrganizeLayers3 from '@assets/images/organizelayers/organizelayers_3.png';
import OrganizeLayers4 from '@assets/images/organizelayers/organizelayers_4.png';
import OrganizeLayers5 from '@assets/images/organizelayers/organizelayers_5.png';
import OrganizeLayers6 from '@assets/images/organizelayers/organizelayers_6.png';
import OrganizeLayers7 from '@assets/images/organizelayers/organizelayers_7.png';
import OrganizeLayers8 from '@assets/images/organizelayers/organizelayers_8.png';
import OrganizeLayers9 from '@assets/images/organizelayers/organizelayers_9.png';
import OrganizeLayersCarousel1 from '@assets/images/organizelayers/organizelayers_carousel_1.png';
import OrganizeLayersCarousel2 from '@assets/images/organizelayers/organizelayers_carousel_2.png';
import OrganizeLayersCarousel3 from '@assets/images/organizelayers/organizelayers_carousel_3.png';
import OrganizeLayersCarousel4 from '@assets/images/organizelayers/organizelayers_carousel_4.png';
import OrganizeLayersMoveUp from '@assets/images/organizelayers/move-up.gif';
import OrganizeLayersMoveDown from '@assets/images/organizelayers/move-down.gif';
import OrganizeLayersReverseLayers from '@assets/images/organizelayers/reverse-layers.gif';

const OrganizeLayers = (props) => {
  return (
    <ProjectBase {...props}>
      <header>
        Top-Down or Bottom-Up?
        <br />
        A Mental Model at the Intersection of Design
        <br />
        and Programmatic Thinking
      </header>
      <Text>
        Bridging an understanding of process and workflow between designers,
        developers and the framework of the Sketch interface.
      </Text>
      <Image src={OrganizeLayers1} style={{ width: '456px' }} />
      <Text>Does order matter? Well, that depends who you ask.</Text>
      <Text size="small" variant={2} textAlign="left">
        Sketch is a prototyping tool with incredible capabilities, emphasizing
        application and digital design development using design components like
        symbols, overriding colors, fonts and shapes to indicate various states,
        etc. I created a plugin that allows using a series of short key commands
        to freely manipulate the position of layers in the layer’s palette.
      </Text>
      <TextTwoColumns>
        <Text textAlign="left">
          The placement of new layers are counter-intuitive to how I approach
          design. Top-down versus bottom-up?
        </Text>
        <Text variant={2} textAlign="left">
          Using the example of building a website, the first holistic drawing of
          a website begins with a header, hero image, body content and footer.
          However, the mental model is considered a top-down approach. Not just
          by sheer location, but the idea beginning as a generic idea, down to a
          specific element.
        </Text>
      </TextTwoColumns>
      <Image src={OrganizeLayers2} />
      <Text size="small" variant={2} textAlign="left">
        However, you’ll see in the layers palette that the order of the layers
        is created based on the oldest created layer starting at the bottom and
        having the newest layer on top. In the case of looking at a sample
        website, essentially it expects users to think of their end product
        development backwards by thinking to create the footer first and then
        the header navigation, according to the order that is reflected in
        Sketch’s layer palette.
      </Text>
      <TextTwoColumns>
        <Text textAlign="left">
          The chaos that comes with designing, iterating and having a lean,
          streamlined file.
        </Text>
        <Text variant={2} textAlign="left">
          With the stress of rapidly prototyping, iterating and creating a
          myriad of layers, there seems to be a less-than intuitive way to
          manipulate the hierarchy of layers that respects the way users
          organize their layers and overall reduce time spent on cleaning their
          workflow.
        </Text>
      </TextTwoColumns>
      <Image src={OrganizeLayers3} style={{ width: '754px' }} />
      <Text size="small" variant={2} textAlign="left">
        The sketch plugin organizeLayers consists of a series of short key
        commands that allows users to quickly move and organize layers in a
        consecutive sequence in individual selections and in batch layers.
      </Text>
      <Carousel>
        <img src={OrganizeLayersCarousel2} />
        <img src={OrganizeLayersCarousel3} />
        <img src={OrganizeLayersCarousel4} />
        <img src={OrganizeLayersCarousel1} />
      </Carousel>
      <TextTwoColumns>
        <Text textAlign="left">Planning and Defining a Solution Strategy</Text>
        <Text variant={2} textAlign="left">
          Instead of getting caught up in all the Javascript and API
          documentation that was obscure and overwhelming to me to begin with, I
          stuck to what I knew how to do and in the method I knew best: writing
          “pseudo-code” and drafting a step-by-step, progressive solution.
          <br />
          <br />
          —<br />
          <br />
          The task I want to achieve is to be able to move a selected layer(s)
          up and/or down using a series of designated short key commands.
        </Text>
      </TextTwoColumns>
      <Image src={OrganizeLayers4} style={{ width: '720px' }} />
      <TextTwoColumns>
        <Text textAlign="left">
          Designing the Script: Defining the terms and conditions
        </Text>
        <Text variant={2} textAlign="left">
          In order to write the script, I began designing a framework as to the
          parts and pieces I needed in define in order to write the solution to
          my script in discrete actions.
        </Text>
      </TextTwoColumns>
      <Image src={OrganizeLayers5} />
      <TextTwoColumns>
        <Text textAlign="left">
          Logic:
          <br />
          Does this script make sense?
        </Text>
        <Text variant={2} textAlign="left">
          By outlining the concrete terms, I’ve assigned what these concrete
          terms should do and how each action created a loop.
        </Text>
      </TextTwoColumns>
      <Image src={OrganizeLayers6} />
      <Image src={OrganizeLayers7} />
      <Image src={OrganizeLayers8} style={{ width: '713px' }} />
      <Image src={OrganizeLayers9} />
      <Carousel>
        <img src={OrganizeLayersMoveUp} />
        <img src={OrganizeLayersMoveDown} />
        <img src={OrganizeLayersReverseLayers} />
      </Carousel>
      <CarouselCaption>
        Move-up, Move-down, and Reverse-layers in action when accessing and
        organizing layers for a food app interface.
      </CarouselCaption>
      <Text size="small" style={{ marginTop: '60px' }}>
        When Design and Development meet through
        <br />
        Sketch and API documentation
      </Text>
      <Text size="small" variant={2} textAlign="left">
        Through the process of designing and developing a Sketch plug-in, there
        were so many great insights as to how the Sketch interface lent itself
        to the development environment. With terminals and areas where code
        could be tested, seeing how Sketch interprets the very actions that
        designers do day-in, day-out, these interactions were all being
        documented through lines of code. By seeing printed code, as well as
        learning to debug and learning new problem solving approaches, not only
        was creating a tangible result a learning experience, but the invaluable
        lesson on how to ask good questions and logical process to solve
        ambiguous and complex problems by reading documentation and guidance
        within a programming environment and the developer community were marked
        outcomes.
      </Text>
    </ProjectBase>
  );
};

export default OrganizeLayers;
