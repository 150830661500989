import styled from 'styled-components';

const Text = styled.div`
  margin-top: 30px;
  font-family: 'Noto Serif KR', serif;
  font-weight: 300;
  font-size: 24px;

  text-align: ${(props) => props.textAlign || 'center'};

  /* Variant #2 styles */
  ${(props) => props.variant === 2 && 'font-size: 14px;'}
  ${(props) => props.variant === 2 && 'line-height: 24px;'}

  /* Width */
  ${(props) => props.size === 'medium' && 'max-width: 720px'}
  ${(props) => props.size === 'small' && 'max-width: 620px'}
  ${(props) => !props.size && 'padding: 0 30px;'}
`;

export default Text;
