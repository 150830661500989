import React from 'react';
import styled from 'styled-components';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';

const CarouselContainer = styled.div`
  margin: 65px 0 45px;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
  }

  .slide {
    background-color: #fff;
  }

  ${(props) =>
    props.centerImages &&
    '.carousel__inner-slide { display: flex; align-items: center; }'}

  .dot-group {
    margin-top: 40px;

    .carousel__dot {
      height: 10.24px;
      width: 10.24px;
      padding: 0;
      border: transparent;
      border-radius: 100%;
      box-shadow: none;
      background-color: #d8d8d8;

      &:not(:last-of-type) {
        margin-right: 10.24px;
      }
    }

    .carousel__dot--selected {
      background-color: #000;
    }
  }
`;

export const CarouselCaption = styled.p`
  max-width: 545px;
  margin: 0;
  font-family: 'Nimbus Sans', sans-serif;
  font-size: 18px;
  color: #4a5459;
  letter-spacing: 0.28px;
  text-align: center;
  line-height: 22px;
`;

const CustomCarousel = ({ children, centerImages }) => {
  const slides = children.map((child, idx) => (
    <Slide key={idx} index={idx}>
      {child}
    </Slide>
  ));

  return (
    <CarouselContainer centerImages={centerImages}>
      <CarouselProvider
        isIntrinsicHeight
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={slides.length}
      >
        <Slider>{slides}</Slider>
        <DotGroup className="dot-group" />
      </CarouselProvider>
    </CarouselContainer>
  );
};

export default CustomCarousel;
