import React from 'react';
import styled from 'styled-components';
import EmailIcon from '@assets/images/email.svg';
import ProfilePicture from '@assets/images/profile.png';

/* -----------------------------------------------------------------
 * Styles for the AboutView.
 * ----------------------------------------------------------------- */

const AboutViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  grid-template-areas:
    '. about about about about about about about about about about about'
    '. main main main main . image image image image image image'
    '. getInTouch getInTouch getInTouch getInTouch . image image image image image image';

  @media (max-width: 769px) {
    padding-left: 30px;
    padding-right: 30px;
    grid-template-columns: minmax(auto, 500px);
    grid-template-areas:
      'about'
      'main'
      'getInTouch';
  }
`;

const AboutHeader = styled.span`
  grid-area: about;
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 27px;
  margin-bottom: 16px;
`;

const AboutMain = styled.div`
  grid-area: main;
`;

const AboutIAM = styled.div`
  font-family: 'Noto Serif KR', serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 40px;
`;

const AboutOther = styled.div`
  font-family: 'Noto Serif KR';
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;

  ul {
    margin-top: 24px;
    padding-left: 20px;
  }
`;

const AboutMainImage = styled.img`
  grid-area: image;
  width: 100%;

  @media (max-width: 769px) {
    display: none;
  }
`;

const AboutGetInTouch = styled.div`
  grid-area: getInTouch;
  margin-top: 30px;
  align-self: flex-end;
`;

const AboutGetInTouchHeader = styled.div`
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 27px;
  margin-bottom: 20px;
`;

const AboutGetInTouchText = styled.div`
  font-family: 'Noto Serif KR', serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 20px;
`;

const AboutGetInTouchLink = styled.a`
  text-decoration: none;
  color: #000;
  font-family: 'Noto Serif KR';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const AboutGetInTouchLinkIcon = styled.img`
  margin-top: -1px;
  margin-right: 10.25px;
  vertical-align: middle;
`;

/* -----------------------------------------------------------------
 * This is the component that renders the About view.
 * ----------------------------------------------------------------- */

const AboutView = () => {
  return (
    <AboutViewContainer>
      <AboutHeader>About</AboutHeader>
      <AboutMain>
        <AboutIAM>
          I’m a designer who is interested in researching and creating within the intersections of design, technology, and human interactions.
        </AboutIAM>
        <AboutOther>
          Other descriptions include, but are not limited to:
          <ul>
            <li>Advocate of Asian-Americans in the arts<br />and the pursuing of equal representation</li>
            <li>Enthusiast of long runs in colder climate</li>
            <li>Matcha green tea fan</li>
          </ul>
        </AboutOther>
      </AboutMain>
      <AboutMainImage src={ProfilePicture} />
      <AboutGetInTouch>
        <AboutGetInTouchHeader>Get In Touch</AboutGetInTouchHeader>
        <AboutGetInTouchText>I am always looking for opportunities to collaborate and create solutions.</AboutGetInTouchText>
        <AboutGetInTouchLink href='mailto:eunice.j.son@gmail.com'>
          <AboutGetInTouchLinkIcon src={EmailIcon} />
          Let's make something wonderful.
        </AboutGetInTouchLink>
      </AboutGetInTouch>
    </AboutViewContainer>
  );
};

export default AboutView;
