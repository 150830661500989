import React from 'react';
import styled from 'styled-components';

/* -----------------------------------------------------------------
 * Styles for the Footer.
 * ----------------------------------------------------------------- */

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  padding-top: 20px;
  padding-bottom: 35px;
  background-color: #fff;
  font-family: 'Neue Haas Grotesk', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 4px;
  text-align: center;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

/* -----------------------------------------------------------------
 * This is the component that renders the site footer.
 * ----------------------------------------------------------------- */

const Footer = () => {
  return <FooterContainer>experience • product designer</FooterContainer>;
};

export default Footer;
