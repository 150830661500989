import styled from 'styled-components';

const Title = styled.div`
  padding-bottom: 10px;
  font-family: 'Noto Serif KR', serif;
  font-size: 24px;
  font-weight: 300;
  text-decoration: ${props => props.isHovering ? 'underline' : 'none'};
  cursor: ${props => props.isHovering ? 'pointer' : 'none'};
  line-height: 35px;
  transition: text-shadow .3s;

  ${props => props.isHovering
    ? ':hover { font-weight: 700; }'
    : ''}
`;

export default Title;
